import $ from 'jquery'
import "./css/frontend.scss"

/*
* @plugin B2W Seach Bar Plugin
*
* -- Archivo Principal
*
* Inicialización del Plugin
* Bootstraping de todas las funcionalidades
*
*
*/

$(function () {
	// CARGAR APP
	const app = new Object();
	app.prefix = 'b2w_search_plugin';
	app.container = $("body");
	app.backgroundImg = b2wSearchBarData.backgroundSearch;
	let urlApi = b2wSearchBarData.api_endpoint_referencias_motos;

	if (b2wSearchBarData.env === 'local') urlApi = "https://prueba.web2.fireboldweb.com/wp-json/referencias/v1/search";

	// OBTENER TODAS LAS REFENCIAS DESDE EL API
	$.get(urlApi)
		.done((res) => {
			app.data = res.data;
			// OCULTAR EL HEADER DE LAS PAGINAS (MENU, LOGO) DETRAS DEL ELEMENTO DE RESULTADO DE BUSQUEDAS
			aplicar_cambios_header_pages();

			// CONSTRUIR ELEMENTO EN EL DOM
			app.barraBusquedaElem = construir_barra_busqueda_elemento(app);

			set_inicial_estado(app);

			// ATAR EVENTOS A LOS ELEMENTOS
			atar_eventos_dom(app);
		})
		.fail(() => { console.log("Error con el API."); });

});

function aplicar_cambios_header_pages() {
	const elem = $(".elementor-location-header").first();

	if (!elem) return;

	elem.css("position", "relative");
	elem.css("z-index", "1");
}

function construir_barra_busqueda_elemento(app) {
	app.container.prepend(`
		<div id="${app.prefix}-contenedor-barra-busqueda" style="background-image: url('${app.backgroundImg}');" class="b2w-search-bar-estilos-encapsulados"> 
			<div class="background__linear">
				<h3 class="title__search__bar">
					<!-- svg class="bike__icon__initial" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M342.5 32C357.2 32 370.7 40.05 377.6 52.98L391.7 78.93L439.1 39.42C444.9 34.62 452.1 32 459.6 32H480C497.7 32 512 46.33 512 64V96C512 113.7 497.7 128 480 128H418.2L473.3 229.1C485.5 226.1 498.5 224 512 224C582.7 224 640 281.3 640 352C640 422.7 582.7 480 512 480C441.3 480 384 422.7 384 352C384 311.1 402.4 276.3 431.1 252.8L415.7 224.2C376.1 253.4 352 299.8 352 352C352 362.1 353.1 373.7 355.2 384H284.8C286.9 373.7 287.1 362.1 287.1 352C287.1 263.6 216.4 192 127.1 192H31.1V160C31.1 142.3 46.33 128 63.1 128H165.5C182.5 128 198.7 134.7 210.7 146.7L255.1 192L354.1 110.3L337.7 80H279.1C266.7 80 255.1 69.25 255.1 56C255.1 42.75 266.7 32 279.1 32L342.5 32zM448 352C448 387.3 476.7 416 512 416C547.3 416 576 387.3 576 352C576 316.7 547.3 288 512 288C509.6 288 507.2 288.1 504.9 288.4L533.1 340.6C539.4 352.2 535.1 366.8 523.4 373.1C511.8 379.4 497.2 375.1 490.9 363.4L462.7 311.2C453.5 322.3 448 336.5 448 352V352zM253.8 376C242.5 435.2 190.5 480 128 480C57.31 480 0 422.7 0 352C0 281.3 57.31 224 128 224C190.5 224 242.5 268.8 253.8 328H187.3C177.9 304.5 154.9 288 128 288C92.65 288 64 316.7 64 352C64 387.3 92.65 416 128 416C154.9 416 177.9 399.5 187.3 376H253.8zM96 352C96 334.3 110.3 320 128 320C145.7 320 160 334.3 160 352C160 369.7 145.7 384 128 384C110.3 384 96 369.7 96 352z"/></svg>
					<br --> ¿Buscando la moto de tus sueños?
				</h3>
				<div class="search__bar">
					<form id="${app.prefix}-form-search-results" action="resultados-referencias-dealer">
						<div id="${app.prefix}-search-input-container" class="input__container">
							<input id="${app.prefix}-search-input" name="q" type="text" placeholder="Escribe el modelo o marca aquí">
							<svg class="btn__search__icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
								<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
							</svg>
						</div>
					</form>
					<ul id="${app.prefix}-search-results" class="search__results">
						
					</ul>
				</div>
			</div>
		</div>
	`);

	return {
		barraBusqueda: $(`#${app.prefix}-contenedor-barra-busqueda`),
		inputContainer: $(`#${app.prefix}-search-input-container`),
		input: $(`#${app.prefix}-search-input`),
		searchResults: $(`#${app.prefix}-search-results`),
		formSearch: $(`#${app.prefix}-form-search-results`)
	};
}

function set_inicial_estado(app) {
	app.barraBusquedaElem.inputContainer.removeClass('semi_rounded_input');
	app.barraBusquedaElem.inputContainer.addClass('rouded__input');
	app.barraBusquedaElem.searchResults.removeClass('open__results__sections');
	app.barraBusquedaElem.searchResults.hide();
	app.barraBusquedaElem.formSearch.off();
	app.barraBusquedaElem.formSearch.on('submit', (e) => {
		let value = app.barraBusquedaElem.input.val();

		if( value.length <= 2 ) e.preventDefault();
	});
}

function atar_eventos_dom(app) {
	app.barraBusquedaElem.input.off();

	app.barraBusquedaElem.input.keyup(event => {
		const term = event.target.value;
		if (!term) return set_inicial_estado(app);

		populate_search_results_to_dom(app, term);
		app.barraBusquedaElem.inputContainer.removeClass('rouded__input');
		app.barraBusquedaElem.inputContainer.addClass('semi_rounded_input');
		app.barraBusquedaElem.searchResults.removeClass('close__results__sections');
		app.barraBusquedaElem.searchResults.addClass('open__results__sections');
		return app.barraBusquedaElem.searchResults.show();
	})
}

function populate_search_results_to_dom(app, term) {
	app.barraBusquedaElem.searchResults.empty();

	if (term.length <= 2) return app.barraBusquedaElem.searchResults.html(`<li class="result__item">
		<span>Escribe al menos 3 letras</span>
	</li>`);

	let datosFiltrados = filter_data_to_populate(app.data, term);

	if (datosFiltrados.length === 0) return app.barraBusquedaElem.searchResults.html(`<li class="result__item">
		<span>No hemos encontrado nada por la palabra <b>"${term}"</b></span>
	</li>`)

	app.barraBusquedaElem.searchResults.html(() => datosFiltrados.map(el => `
		<a href="${el.permalink}">
			<li class="result__item">
				<img class="product__img" src="${el.image}" alt="">
				<span><b>${el.titulo}</b><br>
				<span class="badge badge-default">${el.marca}</span><span class="badge badge-warning">$ ${el.precio}</span><span class="badge badge-success">C.C: ${el.cilindraje}</span></span>
			</li>
		</a>
	`).join(''));

	return;
}

function filter_data_to_populate(data, term) {
	// filtrar datos
	let filterTerm = term.toLowerCase();
	let results = data.filter(elem => {
		let title = elem.titulo.toLowerCase();
		let brand = elem.marca.toLowerCase();

		return title.includes(filterTerm) || brand.includes(filterTerm)
	});

	return results;
}